import React from 'react';
import {
  Redirect,
  Route,
  useLocation,
} from 'react-router-dom';
import AuthRokuRegisterSession from 'src/modules/auth/authRokuRegisterSession';
import PermissionChecker from 'src/modules/auth/permissionChecker';

function RokuRegistrationRoute({
  component: Component,
  currentTenant,
  currentUser,
  permissionRequired,
  ...rest
}) {
  const location = useLocation();

  return (
    <Route
      {...rest}
      render={(props) => {
        const permissionChecker = new PermissionChecker(
          currentTenant,
          currentUser,
        );

        if (!permissionChecker.isAuthenticated) {
          AuthRokuRegisterSession.set(true);
          return (
            <Redirect
              to={{
                pathname: '/auth/signin',
                state: { from: location },
              }}
            />
          );
        }

        if (!permissionChecker.isEmailVerified) {
          AuthRokuRegisterSession.set(true);
          return <Redirect to="/auth/email-unverified" />;
        }

        // if (!permissionChecker.isRegistrationComplete) {
        //   return (
        //     <Redirect to="/auth/complete-registration" />
        //   );
        // }

        // if (!permissionChecker.isSubscribed) {
        //   return <Redirect to="/auth/subscription" />;
        // }

        if (
          permissionChecker.isPasswordChangeRequired &&
          props.location.pathname !==
            '/settings/password-change'
        ) {
          AuthRokuRegisterSession.set(true);
          return (
            <Redirect to="/settings/password-change" />
          );
        }

        if (!permissionChecker.match(permissionRequired)) {
          AuthRokuRegisterSession.set(true);
          return <Redirect to="/403" />;
        }

        return <Component {...props} />;
      }}
    />
  );
}

export default RokuRegistrationRoute;
