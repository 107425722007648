import React, { useEffect } from 'react';
import {
  Redirect,
  Route,
  useLocation,
} from 'react-router-dom';
import AuthRokuRegisterSession from 'src/modules/auth/authRokuRegisterSession';
import PermissionChecker from 'src/modules/auth/permissionChecker';
import Layout from 'src/view/layout/Layout';

function PrivateRoute({
  component: Component,
  currentTenant,
  currentUser,
  permissionRequired,
  ...rest
}) {
  const location = useLocation();

  const chatBot = document.getElementById("gpt-trainer-window-button");
  const chatBotOpenIcon = document.getElementById("gpt-trainer-open-chat-icon");
  useEffect(()=>{
     if(localStorage.getItem("jwt") && chatBot){
       chatBot.style.display = "flex"
       chatBotOpenIcon.style.display = "flex"

     }else if((!localStorage.getItem("jwt")|| localStorage.getItem("jwt")==="") && chatBot){
         chatBot.style.display = "none"
     }
   },[chatBot])

  return (
    <Route
      {...rest}
      render={(props) => {
        const permissionChecker = new PermissionChecker(
          currentTenant,
          currentUser,
        );

        if (AuthRokuRegisterSession.get()) {
          return (
            <Redirect
              to={{
                pathname: '/register',
              }}
            />
          );
        }

        if (!permissionChecker.isAuthenticated) {
          return (
            <Redirect
              to={{
                pathname: '/auth/signin',
                state: { from: location },
              }}
            />
          );
        }

        if (!permissionChecker.isEmailVerified) {
          return <Redirect to="/auth/email-unverified" />;
        }

        // if (!permissionChecker.isRegistrationComplete) {
        //   return (
        //     <Redirect to="/auth/complete-registration" />
        //   );
        // }

        // if (!permissionChecker.isSubscribed) {
        //   return <Redirect to="/auth/subscription" />;
        // }

        if (
          permissionChecker.isPasswordChangeRequired &&
          props.location.pathname !==
            '/settings/password-change'
        ) {
          return (
            <Redirect to="/settings/password-change" />
          );
        }

        if (!permissionChecker.match(permissionRequired)) {
          return <Redirect to="/403" />;
        }

        return (
          <Layout {...props}>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
}

export default PrivateRoute;
