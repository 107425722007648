import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";


const firebaseConfig = {
  apiKey: "AIzaSyD8MnKPSCmeom9cYJzBWcO4Xx630F3cugY",
  authDomain: "soaak-app-production.firebaseapp.com",
  projectId: "soaak-app-production",
  storageBucket: "soaak-app-production.appspot.com",
  messagingSenderId: "413683795259",
  appId: "1:413683795259:web:8eb6d362be48a1ee58a31e",
  measurementId: "G-ZSJMQDKT8L"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);


