import authAxios from 'src/modules/shared/axios/authAxios';
import moment from 'moment';

export default class AffirmationService {
  static async listUnlockedForProgram(programId) {
    const response = await authAxios.get(
      `/affirmation/program/${programId}/unlocked`,
    );
    return response.data;
  }

  static async listFavorites() {
    const response = await authAxios.get(
      `/affirmation/favorite`,
    );
    return response.data;
  }

  static async addToFavorites(id) {
    const response = await authAxios.post(
      `/affirmation/${id}/favorite`,
    );
    return response.data;
  }

  static async removeFromFavorites(id) {
    const response = await authAxios.delete(
      `/affirmation/${id}/favorite`,
    );
    return response.data;
  }

  static async getToday() {
    const response = await authAxios.get(
      `/affirmation/today`,
    );

    return response.data;
  }

  static async find(id) {
    const response = await authAxios.get(
      `/affirmation/${id}`,
    );

    return response.data;
  }

  static async findByProgramSlugAndOrderInList(
    programSlug,
    orderInList,
  ) {
    const response = await authAxios.get(
      `/affirmation/${programSlug}/${orderInList}`,
    );

    return response.data;
  }

  static async listSent() {
    const response = await authAxios.get(
      `/affirmation/sent`,
      {
        params: {
          createdAtAfter: moment().subtract(1, 'months', ),
        },
      },
    );

    return response.data;
  }
}
