const usageLogTypes = {
  APPLICATION_OPEN: 'APPLICATION_OPEN',
  AFFIRMATION_VIEW: 'AFFIRMATION_VIEW',
  AFFIRMATION_SENT: 'AFFIRMATION_SENT',
  PROGRAM_21_DAY_START: 'PROGRAM_21_DAY_START',
  FREQUENCY_PLAY: 'FREQUENCY_PLAY',
  FREQUENCY_PAUSE: 'FREQUENCY_PAUSE',
  FREQUENCY_ABORT: 'FREQUENCY_ABORT',
  FREQUENCY_ENDED: 'FREQUENCY_ENDED',
  DEEP_DIVE_PLAY: 'DEEP_DIVE_PLAY',
  DEEP_DIVE_PAUSE: 'DEEP_DIVE_PAUSE',
  DEEP_DIVE_ABORT: 'DEEP_DIVE_ABORT',
  DEEP_DIVE_ENDED: 'DEEP_DIVE_ENDED',
};

export default usageLogTypes;
