import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { i18n } from 'src/i18n';
import authActions from 'src/modules/auth/authActions';
import { default as authSelectors } from 'src/modules/auth/authSelectors';
import PermissionChecker from 'src/modules/auth/permissionChecker';
import layoutActions from 'src/modules/layout/layoutActions';
import layoutSelectors from 'src/modules/layout/layoutSelectors';
import DownloadApp from 'src/modules/shared/modal/DownloadApp';
import menus from 'src/view/menus';

function Menu(props) {
  const dispatch = useDispatch();
  const logoUrl = `/images/logo-color.png`;
  const {showDownloadAppModal, setShowDownloadAppModal} = props;
  const doSignout = () => {
    dispatch(authActions.doSignout());
  };

  const currentTenant = useSelector(
    authSelectors.selectCurrentTenant,
  );
  const currentUser = useSelector(
    authSelectors.selectCurrentUser,
  );
  const menuVisible = useSelector(
    layoutSelectors.selectMenuVisible,
  );

  const doToggleMenu = () => {
    dispatch(layoutActions.doToggleMenu());
  };

  const doToggleMenuIfSmall = () => {
    if (window.innerWidth < 768) {
      dispatch(layoutActions.doToggleMenu());
    }
    setShowDownloadAppModal(true);
  };

  const doCloseIfSmall = () => {
    if (window.innerWidth < 768) {
      dispatch(layoutActions.doHideMenu());
    }
  };

  useLayoutEffect(() => {
    doCloseIfSmall();
  }, []);

  const permissionChecker = new PermissionChecker(
    currentTenant,
    currentUser,
  );

  const selectedKeys = () => {
    const url = props.url;

    const match = menus.find((option) => {
      // @ts-ignore
      if (option.exact) {
        return url === option.path;
      }

      return (
        url === option.path ||
        url.startsWith(option.path + '/')
      );
    });

    if (match) {
      return [match.path];
    }

    return [];
  };

  const match = (permission) => {
    return permissionChecker.match(permission);
  };

  if (!menuVisible) {
    return null;
  }

  return (
    <>
      <div className="fixed inset-0 z-50 flex lg:hidden">
        <div className="fixed inset-0 bg-gray-600 bg-opacity-75"></div>

        <div
          className={`relative flex w-full max-w-xs flex-1 flex-col bg-white pt-5 pb-4`}
        >
          <div className="absolute top-0 right-0 -mr-14 p-1">
            <button
              onClick={doToggleMenu}
              className="focus:outline-none flex h-12 w-12 items-center justify-center rounded-full focus:bg-gray-600"
            >
              <svg
                className="h-6 w-6 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="flex flex-shrink-0 items-center justify-center px-4">
            <Link onClick={doToggleMenuIfSmall} to="/">
              <img
                src={logoUrl}
                className="object-fit max-h-8"
                alt={i18n('app.title')}
              />
            </Link>
          </div>

          <div className='bg-white rounded-2xl flex flex-shrink-0 items-center justify-center pr-10 pl-4 gap-3 mx-auto py-3 mt-8 mb-1' style={{boxShadow :'0px 4px 14px 0px rgba(0, 0, 0, 0.05)', width:'fit-content'}}>
                <div>
                  <img src="/images/user-profile.png" className='rounded-full w-12 h-12'/>
                </div>
                <div>
                  <p className='text-xs font-medium text-black text-opacity-30'>Welome back</p>
                  <h1 className='text-base font-semibold'><span style={{textTransform:"capitalize"}}>{currentUser.fullName}</span></h1>
                </div>
          </div>

          <div className="mt-5 h-0 flex-1 overflow-y-auto mx-auto">
            <nav className="flex h-full flex-col">
              <div className="space-y-1">
                {menus
                  .filter((menu) =>
                    match(menu.permissionRequired),
                  )
                  .map((menu, index) =>
                    menu.href ? (
                      <a
                        className={`group flex items-center py-2 px-3 text-base font-medium text-darkGreen hover:bg-lightGreen custom-fa-icon`}
                        onClick={doToggleMenuIfSmall}
                        key={menu.href}
                        href={menu.href}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {menu.imageIcon ? (
                          <img
                            src={menu.imageIcon}
                            className="mr-4 h-5 w-5 object-contain"
                            alt=""
                          />
                        ) : (
                          <FontAwesomeIcon
                            className={`mr-4 h-5 w-5 text-darkGreen`}
                            icon={menu.icon}
                            size="6x"
                          />
                        )}
                        <span className="truncate">
                          {menu.label}
                        </span>
                      </a>
                    ) : (
                      <Link
                        className={`${
                          selectedKeys().includes(menu.path)
                            ? 'group flex items-center  bg-lightGreen py-2 px-3 text-base font-medium text-darkGreen justify-start rounded-lg w-11/12 custom-fa-icon'
                            : 'group flex items-center  py-2 px-3 text-base font-medium text-darkGreen hover:bg-lightGreen justify-start rounded-lg w-11/12 custom-fa-icon'
                        }`}
                        onClick={doToggleMenuIfSmall}
                        key={menu.path}
                        to={menu.path}
                      >
                        {menu.imageIcon ? (
                          <img
                            src={menu.imageIcon}
                            className="mr-4 h-5 w-5 object-contain"
                            alt=""
                          />
                        ) : (
                          <FontAwesomeIcon
                            className={`mr-4 h-5 w-5 text-darkGreen`}
                            icon={menu.icon}
                          />
                        )}
                        <span className="truncate">
                          {menu.label}
                        </span>
                      </Link>
                    ),
                  )}
              </div>
              <div className="mt-auto space-y-1 pt-5">
                <div className="flex space-x-4 py-2 px-3">
                  <a
                    href="https://www.facebook.com/dailysoaak"
                    className="text-darkGreen"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="sr-only">
                      Facebook
                    </span>
                    <img
                      className="h-6 w-6"
                      src="/images/facebook.png"
                      alt="facebook"
                    />
                  </a>

                  <a
                    href="https://www.instagram.com/dailysoaak"
                    className="text-darkGreen"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="sr-only">
                      Instagram
                    </span>
                    <img
                      className="h-6 w-6"
                      src="/images/instagram.png"
                      alt="instagram"
                    />
                  </a>

                  <a
                    href="https://www.linkedin.com/company/soaak"
                    className="text-darkGreen"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="sr-only">
                      Linkedin
                    </span>
                    <img
                      className="h-6 w-6"
                      src="/images/linkedin.png"
                      alt="linkedin"
                    />
                  </a>

                  <a
                    href="https://www.tiktok.com/@dailysoaak"
                    className="text-darkGreen"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="sr-only">TikTok</span>
                    <img
                      className="h-6 w-6"
                      src="/images/tiktok.png"
                      alt="tiktok"
                    />
                  </a>
                </div>

                <button
                  onClick={doSignout}
                  className="group flex w-full items-center border-l-4 border-transparent py-2 px-3 font-jakartaMedium text-sm font-medium text-darkGreen hover:bg-gray-50"
                >
                  {/* <FontAwesomeIcon
                    className="mr-3 h-6 w-6 text-darkGreen"
                    icon={faSignOutAlt}
                  /> */}
                  <img
                      className="mr-3 h-5 w-5 text-darkGreen"
                      src="/images/sign-out.svg"
                      alt="sign-out"
                    />
                  {i18n('auth.signout')}
                </button>
              </div>
            </nav>
          </div>
        </div>

        <div
          className="w-14 flex-shrink-0"
          aria-hidden="true"
        ></div>
      </div>

      <div className="hidden lg:flex lg:flex-shrink-0">
        <div className="flex w-64 flex-col">
          <div className="flex flex-grow flex-col overflow-y-auto bg-white pt-5 pb-4">
            <div className="flex flex-shrink-0 items-center justify-center px-4">
              <Link onClick={doToggleMenuIfSmall} to="/">
                <img
                  src={logoUrl}
                  className="object-fit max-h-6"
                  alt={i18n('app.title')}
                />
              </Link>
            </div>

            <div className='bg-white rounded-2xl flex flex-shrink-0 items-center justify-center pr-10 pl-4 gap-3 mx-auto py-3 mt-10 mb-1' style={{boxShadow :'0px 4px 14px 0px rgba(0, 0, 0, 0.05)', width:'fit-content'}}>
                <div>
                  <img src="/images/user-profile.png" className='rounded-full w-12 h-12'/>
                </div>
                <div>
                  <p className='text-xs font-medium text-black text-opacity-30'>Welome back</p>
                  <h1 className='text-base font-semibold'><span style={{textTransform:"capitalize"}}>{currentUser.fullName}</span></h1>
                </div>
            </div>

            <div className="mt-5 flex flex-grow flex-col mx-auto px-4">
              <div className="flex-1 space-y-1">
                {menus
                  .filter((menu) =>
                    match(menu.permissionRequired),
                  )
                  .map((menu, index) =>
                    menu.href ? (
                      <a
                        className={`group flex items-center py-2 px-3 text-sm font-medium text-gray-600 hover:bg-lightGreen hover:text-gray-900 justify-start rounded-lg w-full custom-fa-icon`}
                        onClick={doToggleMenuIfSmall}
                        key={menu.href}
                        href={menu.href}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {menu.imageIcon ? (
                          <img
                            src={menu.imageIcon}
                            className="mr-4 h-5 w-5 object-contain"
                            alt=""
                          />
                        ) : (
                          <FontAwesomeIcon
                            className={`mr-4 h-5 w-5 text-darkGreen`}
                            icon={menu.icon}
                          />
                        )}
                        <span className="truncate">
                          {menu.label}
                        </span>
                      </a>
                    ) : (
                      <Link
                        className={`
                        ${
                          selectedKeys().includes(menu.path)
                            ? 'group flex items-center bg-lightGreen py-2 px-3 text-sm font-medium text-darkGreen justify-start rounded-lg w-11/12 custom-fa-icon'
                            : 'group flex items-center py-2 px-3 text-sm font-medium text-darkGreen hover:bg-lightGreen justify-start rounded-lg w-11/12 custom-fa-icon'
                        }`}
                        onClick={doToggleMenuIfSmall}
                        key={menu.path}
                        to={menu.path}
                      >
                        {menu.imageIcon ? (
                          <img
                            src={menu.imageIcon}
                            className="mr-4 h-5 w-5 object-contain"
                            alt=""
                          />
                        ) : (
                          <FontAwesomeIcon
                            className={`mr-4 h-5 w-5 text-darkGreen`}
                            icon={menu.icon}
                          />
                        )}
                        <span style={{ whiteSpace: "nowrap" }} >
                          {menu.label}
                        </span>
                      </Link>
                    ),
                  )}
              </div>
              <div className="block w-full flex-shrink-0">
                <div className="flex space-x-4 py-2 px-3">
                  <a
                    href="https://www.facebook.com/dailysoaak"
                    className="text-darkGreen"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="sr-only">
                      Facebook
                    </span>
                    <img
                      className="h-6 w-6"
                      src="/images/facebook.png"
                      alt="facebook"
                    />
                  </a>

                  <a
                    href="https://www.instagram.com/dailysoaak"
                    className="text-darkGreen"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="sr-only">
                      Instagram
                    </span>
                    <img
                      className="h-6 w-6"
                      src="/images/instagram.png"
                      alt="instagram"
                    />
                  </a>

                  <a
                    href="https://www.linkedin.com/company/soaak"
                    className="text-darkGreen"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="sr-only">
                      Linkedin
                    </span>
                    <img
                      className="h-6 w-6"
                      src="/images/linkedin.png"
                      alt="linkedin"
                    />
                  </a>

                  <a
                    href="https://www.tiktok.com/@dailysoaak"
                    className="text-darkGreen"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="sr-only">TikTok</span>
                    <img
                      className="h-6 w-6"
                      src="/images/tiktok.png"
                      alt="tiktok"
                    />
                  </a>
                </div>

                <button
                  onClick={doSignout}
                  className="group flex w-full items-center border-l-4 border-transparent py-2 px-3 font-jakartaMedium text-sm font-medium text-darkGreen hover:bg-gray-50"
                >
                  {/* <FontAwesomeIcon
                    className="mr-3 h-6 w-6 text-darkGreen"
                    icon={faSignOutAlt}
                  /> */}
                   <img
                      className="mr-3 h-5 w-5 text-darkGreen"
                      src="/images/sign-out.svg"
                      alt="sign-out"
                    />
                  {i18n('auth.signout')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    
    </>
  );
}

export default Menu;
