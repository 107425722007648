import affirmationSelectors from 'src/modules/affirmation/affirmationSelectors';
import AffirmationService from 'src/modules/affirmation/affirmationService';
import Errors from 'src/modules/shared/error/errors';

const prefix = 'AFFIRMATION';

const affirmationActions = {
  AFFIRMATION_TODAY_START: `${prefix}_TODAY_START`,
  AFFIRMATION_TODAY_SUCCESS: `${prefix}_TODAY_SUCCESS`,
  AFFIRMATION_TODAY_ERROR: `${prefix}_TODAY_ERROR`,

  fetchToday: () => async (dispatch, getState) => {
    try {
      const isAlreadyFetched = affirmationSelectors.selectAffirmationToday(
        getState(),
      );
      if (isAlreadyFetched) {
        return;
      }

      dispatch({
        type: affirmationActions.AFFIRMATION_TODAY_START,
      });
      const affirmation = await AffirmationService.getToday();
      dispatch({
        type: affirmationActions.AFFIRMATION_TODAY_SUCCESS,
        payload: affirmation,
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: affirmationActions.AFFIRMATION_TODAY_ERROR,
      });
    }
  },
};

export default affirmationActions;
