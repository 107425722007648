import React, { useEffect } from 'react'

import {useHistory} from "react-router-dom"
import { i18n } from "src/i18n";


function getDeviceType() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }

    return "PC";
}

const deviceType = getDeviceType();

console.log("Device Type:", getDeviceType());
const DownloadApp = (props) => {
    const history = useHistory();
    const{setShowDownloadAppModal} = props;
    const handleClose = ()=>{
        setShowDownloadAppModal(false);
    }

    
    useEffect(()=>{
        if(window.AF_SMART_SCRIPT_RESULT){
            var result_url = window?.AF_SMART_SCRIPT_RESULT?.clickURL;
        if (result_url) {
            if(document.getElementById('andrd_link')){
                document.getElementById('andrd_link')?.setAttribute('href', result_url);
            }
            if(document.getElementById('ios_link')){
              document.getElementById('ios_link')?.setAttribute('href', result_url);
            }
              // Optionally - Create QR code from the generated OneLink URL
              window?.AF_SMART_SCRIPT?.displayQrCode("my_qr_code_div_id");
              //The size of the QR code is defined in the CSS file under #my_qr_code_div_id
              // #my_qr_code_div_id canvas { 
              //  height: 200px;
              //  width: 200px;
              //}
              // Optionally - fire an impression.
              // The impression will fire to https://impressions.onelink.me//.... 
              setTimeout(() => {
                window?.AF_SMART_SCRIPT?.fireImpressionsLink();
              }, 1000);            
        }
        }
    },[])
   
    
  return (
    <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity modal-width"></div>
        <div className="fixed inset-0 z-10 overflow-y-auto modal-width">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="relative transform overflow-hidden rounded-3xl bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 text-center">
                <div className="mt-3 text-center">
                    <h3 className="text-2xl font-semibold text-darkGreen" id="modal-title">Download our mobile app for the best experience
                    </h3>
                    <img onClick={handleClose} className="absolute top-5 right-5 h-5 w-5 cursor-pointer" src="./images/Audio-cross.svg"/>
                    <div id='my_qr_code_div_id' className="my-7 flex mx-auto items-center justify-center text-center h-full">
                        {/* <a href=''><img src='/images/qr_code.svg' className='img-fluid qr-code-img w-full h-full'></img></a> */}
                    </div>
                
                </div>
                <div className="px-4 py-3 flex justify-center mt-2 gap-x-2">
               { (deviceType === "iOS" || deviceType === "PC") && <a href='' id='ios_link' className='mb-5 sm:mb-2'><img src='/images/app_store.svg'></img></a>}
                 {( deviceType === "Android" || deviceType === "PC") &&  <a href='' id='andrd_link' className='mb-2'><img src='/images/google_play.svg'></img></a>}
                  {/* <button type="button" onClick={()=>history.push('/auth/subscription')} className="focus:outline-none font-medium w-11/12 text-sm  transform rounded-full bg-darkGreen px-3 py-4 font-jakartaMedium tracking-wide text-darkGreen-200 transition-colors duration-200 hover:bg-darkGreen focus:bg-darkGreen disabled:cursor-default disabled:opacity-50 text-white mt-3 ">Subscribe</button>
                  <a href="mailto:support@soaak.com"  className=" focus:outline-none font-medium w-11/12 text-sm  transform rounded-full bg-darkGreen px-3 py-4 font-jakartaMedium tracking-wide text-darkGreen-200 transition-colors duration-200 hover:bg-darkGreen focus:bg-darkGreen disabled:cursor-default disabled:opacity-50 text-white mt-3">Contact Support</a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default DownloadApp