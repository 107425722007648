import { ConnectedRouter } from 'connected-react-router';
import { useEffect } from 'react';
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import { Provider, useSelector } from 'react-redux';
import layoutSelectors from 'src/modules/layout/layoutSelectors';
import {
  configureStore,
  getHistory,
} from 'src/modules/store';
import RoutesComponent from 'src/view/shared/routes/RoutesComponent';

const store = configureStore();
const queryClient = new QueryClient();
const dotenv = require('dotenv');
dotenv.config();


const App = (props) => {
  const chatBot = document.getElementById("gpt-trainer-window-button");
  useEffect(()=>{
     if(localStorage.getItem("jwt") && chatBot){
       chatBot.style.display = "flex"
     }else if((!localStorage.getItem("jwt")|| localStorage.getItem("jwt")==="") && chatBot){
         chatBot.style.display = "none"
     }
   },[chatBot])
  return (
    // @ts-ignore
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        {/* @ts-ignore */}
        <ConnectedRouter history={getHistory()}>
          <AppInnerComponent />
        </ConnectedRouter>
      </QueryClientProvider>
    </Provider>
  );
};

const AppInnerComponent = (props) => {
  const isDarkMode = useSelector(
    layoutSelectors.selectDarkMode,
  );
  return (
    <div
      className={`font-jakartaMedium text-darkGreen ${
        isDarkMode ? 'dark' : ''
      }`}
    >
      <RoutesComponent />
    </div>
  );
};

export default App;
