import PermissionChecker from 'src/modules/auth/permissionChecker';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import queryString from 'query-string';
import { get } from 'lodash';

function SubscriptionRoute({
  component: Component,
  currentUser,
  currentTenant,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) => {
        const permissionChecker = new PermissionChecker(
          currentTenant,
          currentUser,
        );

        if (!permissionChecker.isAuthenticated) {
          return (
            <Redirect
              to={{
                pathname: '/auth/signin',
              }}
            />
          );
        }

        if (permissionChecker.isSubscribed) {
          const subscribed =
            String(
              queryString.parse(props.location.search)
                .subscribed,
            ) === 'true';

          if (subscribed) {
            const price = get(
              currentUser,
              'activeEntitlement.entitlement.retailPrice',
            );
            const plan = `${get(
              currentUser,
              'activeEntitlement.entitlement.title',
            )} ${get(
              currentUser,
              'activeEntitlement.entitlement.renewalPeriod',
            )}`;

            // @ts-ignore
            const dataLayer = window.dataLayer || [];
            // @ts-ignore
            dataLayer.push({ ecommerce: null });
            // @ts-ignore
            dataLayer.push({
              ecommerce: {
                purchase: {
                  products: [
                    {
                      name: plan,
                      price: price,
                      quantity: 1,
                    },
                  ],
                },
              },
            });
          }

          // return <Redirect to="/" />;
        }

        return <Component {...props} />;
      }}
    />
  );
}

export default SubscriptionRoute;
