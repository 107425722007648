import { AuthToken } from 'src/modules/auth/authToken';
import Axios from 'axios';
import axios, { AxiosRequestConfig } from 'axios';
import config from 'src/config';
import { getLanguageCode } from 'src/i18n';
import Qs from 'qs';
import moment from 'moment';
import { logEvent } from '@firebase/analytics';
import { analytics } from 'src/config/firebase';



const authAxios = Axios.create({
  baseURL: config.backendUrl,
  paramsSerializer: function (params) {
    return Qs.stringify(params, {
      arrayFormat: 'brackets',
      filter: (prefix, value) => {
        if (
          moment.isMoment(value) ||
          value instanceof Date
        ) {
          return value.toISOString();
        }
        return value;
      },
    });
  },
});

authAxios.interceptors.request.use(
  async function (options) {
    const token = AuthToken.get();
    const tokenResponse = localStorage.getItem("tokenResponse")
    if (token || tokenResponse) {
      options.headers['Authorization'] = `Bearer ${token? token: tokenResponse}`;
    }

    options.headers['Accept-Language'] = getLanguageCode();

    return options;
  },
  function (error) {
    return Promise.reject(error);
  },
);

authAxios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const tenant = localStorage.getItem("tenant");
         logEvent(analytics, "app_exception", {
      page_title: document.title,
      page_location: window.location.href,
      page_path: window.location.pathname,
      user:tenant?tenant:"sigin or signup",
      error:error
    })
    return Promise.reject(error);
  }
);

export const attributesAxios = (data): Promise<any> => {

  const config: AxiosRequestConfig = {
    method: 'get',
    url: `https://api.id.me/api/public/v3/attributes.json?access_token=${data}`,
    // maxBodyLength: Infinity,
    headers: {
      'Content-Type': 'application/json',
    },
    data: data, 
  };

  return axios.request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      const tenant = localStorage.getItem("tenant");
      logEvent(analytics, "app_exception", {
   page_title: document.title,
   page_location: window.location.href,
   page_path: window.location.pathname,
   user:tenant?tenant:"sigin or signup",
   error:error
 })
      console.error(error, );
      throw error;
    });
};






export default authAxios;
