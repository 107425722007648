export default class AuthRokuRegisterSession {
  static get() {
    return (
      sessionStorage.getItem('rokuRegisterSession') || null
    );
  }

  static set(isRokuRegisterSession) {
    sessionStorage.setItem(
      'rokuRegisterSession',
      isRokuRegisterSession,
    );
  }

  static clear() {
    sessionStorage.removeItem('rokuRegisterSession');
  }
}
