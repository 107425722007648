export class AuthPlan {
  static get() {
    return localStorage.getItem('plan') || null;
  }

  static set(plan) {
    localStorage.setItem('plan', plan || '');
  }

  static applyFromLocationUrlIfExists() {
    const urlParams = new URLSearchParams(
      window.location.search,
    );
    const plan = urlParams.get('plan');

    if (!plan) {
      return;
    }

    this.set(plan);
  }
}
