// Place the URL here with the /api suffix.
// Ex.:`https://domain.com/api`;
const backendUrl = `https://api.soaak.dev/api`;

// SwaggerUI Documentation URL
// Leave black if documentation should be hidden
const apiDocumentationUrl = `https://backend-vrww7bnmia-uc.a.run.app/documentation`;

/**
 * Frontend URL.
 */
const frontendUrl = {
  host: 'app.soaak.com',
  protocol: 'https',
};

const marketingTermsOrServiceUrl =
  'https://soaak.com/terms-of-service/';
const marketingPrivacyPolicyUrl =
  'https://soaak.com/privacy-policy/';

/**
 * Tenant Mode
 * multi: Allow new users to create new tenants.
 * multi-with-subdomain: Same as multi, but enable access to the tenant via subdomain.
 * single: One tenant, the first user to register will be the admin.
 */
const tenantMode = 'single';

/**
 * Plan payments configuration.
 */
const isPlanEnabled = true;
const stripePublishableKey =
  'pk_live_51HYuIJI7OMFn2tF6C9vYrioF2dJmFgxILBjBhpwjJnVUGbUC404uKB9YZNtvNgPt9eS0ExJJ1X0h38dSdZ97pnGB009ny1Y4Lx';

const pixelId = '357164989128215';
const googleTagManagerId = 'GTM-K8BT3XB';

export default {
  googleTagManagerId,
  pixelId,
  frontendUrl,
  backendUrl,
  apiDocumentationUrl,
  tenantMode,
  isPlanEnabled,
  stripePublishableKey,
  marketingTermsOrServiceUrl,
  marketingPrivacyPolicyUrl,
};
