import Roles from 'src/security/roles';
import Plans from 'src/security/plans';
import Storage from 'src/security/storage';

const storage = Storage.values;
const roles = Roles.values;
const plans = Plans.values;

class Permissions {
  static get values() {
    return {
      tenantEdit: {
        id: 'tenantEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      tenantDestroy: {
        id: 'tenantDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      planEdit: {
        id: 'planEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      planRead: {
        id: 'planRead',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userEdit: {
        id: 'userEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userDestroy: {
        id: 'userDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userCreate: {
        id: 'userCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userImport: {
        id: 'userImport',
        allowedRoles: [],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userRead: {
        id: 'userRead',
        allowedRoles: [roles.admin, roles.readonly],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userAutocomplete: {
        id: 'userAutocomplete',
        allowedRoles: [roles.admin, roles.readonly],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      auditLogRead: {
        id: 'auditLogRead',
        allowedRoles: [roles.admin, roles.readonly],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      settingsEdit: {
        id: 'settingsEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [
          storage.settingsBackgroundImages,
          storage.settingsLogos,
        ],
      },
      affirmationImport: {
        id: 'affirmationImport',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      affirmationCreate: {
        id: 'affirmationCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      affirmationEdit: {
        id: 'affirmationEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      affirmationDestroy: {
        id: 'affirmationDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      affirmationRead: {
        id: 'affirmationRead',
        allowedRoles: [roles.admin, roles.readonly],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      affirmationAutocomplete: {
        id: 'affirmationAutocomplete',
        allowedRoles: [roles.admin, roles.readonly],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      affirmationProgramImport: {
        id: 'affirmationProgramImport',
        allowedRoles: [],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      affirmationProgramCreate: {
        id: 'affirmationProgramCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [
          storage.affirmationProgramSquareArt,
          storage.affirmationProgramSquareArtHD,
          storage.affirmationProgramLandscapeArt,
          storage.affirmationProgramLandscapeArtHD,
          storage.affirmationProgramPortraitArt,
          storage.affirmationProgramPortraitArtHD,
        ],
      },
      affirmationProgramEdit: {
        id: 'affirmationProgramEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [
          storage.affirmationProgramSquareArt,
          storage.affirmationProgramSquareArtHD,
          storage.affirmationProgramLandscapeArt,
          storage.affirmationProgramLandscapeArtHD,
          storage.affirmationProgramPortraitArt,
          storage.affirmationProgramPortraitArtHD,
        ],
      },
      affirmationProgramDestroy: {
        id: 'affirmationProgramDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [
          storage.affirmationProgramSquareArt,
          storage.affirmationProgramSquareArtHD,
          storage.affirmationProgramLandscapeArt,
          storage.affirmationProgramLandscapeArtHD,
          storage.affirmationProgramPortraitArt,
          storage.affirmationProgramPortraitArtHD,
        ],
      },
      affirmationProgramRead: {
        id: 'affirmationProgramRead',
        allowedRoles: [roles.admin, roles.readonly],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      affirmationProgramAutocomplete: {
        id: 'affirmationProgramAutocomplete',
        allowedRoles: [roles.admin, roles.readonly],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      soundImport: {
        id: 'soundImport',
        allowedRoles: [],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      soundCreate: {
        id: 'soundCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [
          storage.soundAudioURL,
          storage.soundAlexaAudioURL,
          storage.soundSquareArt,
          storage.soundSquareArtHD,
          storage.soundLandscapeArt,
          storage.soundLandscapeArtHD,
          storage.soundPortraitArt,
          storage.soundPortraitArtHD,
        ],
      },
      soundEdit: {
        id: 'soundEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [
          storage.soundAudioURL,
          storage.soundAlexaAudioURL,
          storage.soundSquareArt,
          storage.soundSquareArtHD,
          storage.soundLandscapeArt,
          storage.soundLandscapeArtHD,
          storage.soundPortraitArt,
          storage.soundPortraitArtHD,
        ],
      },
      soundDestroy: {
        id: 'soundDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [
          storage.soundAudioURL,
          storage.soundAlexaAudioURL,
          storage.soundSquareArt,
          storage.soundSquareArtHD,
          storage.soundLandscapeArt,
          storage.soundLandscapeArtHD,
          storage.soundPortraitArt,
          storage.soundPortraitArtHD,
        ],
      },
      soundRead: {
        id: 'soundRead',
        allowedRoles: [roles.admin, roles.readonly],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      soundAutocomplete: {
        id: 'soundAutocomplete',
        allowedRoles: [roles.admin, roles.readonly],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      soundTitleImport: {
        id: 'soundTitleImport',
        allowedRoles: [],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      soundTitleCreate: {
        id: 'soundTitleCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [
          storage.soundTitleSquareArt,
          storage.soundTitleSquareArtHD,
          storage.soundTitleLandscapeArt,
          storage.soundTitleLandscapeArtHD,
        ],
      },
      soundTitleEdit: {
        id: 'soundTitleEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [
          storage.soundTitleSquareArt,
          storage.soundTitleSquareArtHD,
          storage.soundTitleLandscapeArt,
          storage.soundTitleLandscapeArtHD,
        ],
      },
      soundTitleDestroy: {
        id: 'soundTitleDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [
          storage.soundTitleSquareArt,
          storage.soundTitleSquareArtHD,
          storage.soundTitleLandscapeArt,
          storage.soundTitleLandscapeArtHD,
        ],
      },
      soundTitleRead: {
        id: 'soundTitleRead',
        allowedRoles: [roles.admin, roles.readonly],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      soundTitleAutocomplete: {
        id: 'soundTitleAutocomplete',
        allowedRoles: [roles.admin, roles.readonly],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      soundCategoryImport: {
        id: 'soundCategoryImport',
        allowedRoles: [],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      soundCategoryCreate: {
        id: 'soundCategoryCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      soundCategoryEdit: {
        id: 'soundCategoryEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      soundCategoryDestroy: {
        id: 'soundCategoryDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      soundCategoryRead: {
        id: 'soundCategoryRead',
        allowedRoles: [roles.admin, roles.readonly],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      soundCategoryAutocomplete: {
        id: 'soundCategoryAutocomplete',
        allowedRoles: [roles.admin, roles.readonly],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      subscriptionTierImport: {
        id: 'subscriptionTierImport',
        allowedRoles: [],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      subscriptionTierCreate: {
        id: 'subscriptionTierCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [
          storage.subscriptionTierSquareArt,
          storage.subscriptionTierSquareArtHD,
          storage.subscriptionTierLandscapeArt,
          storage.subscriptionTierLandscapeArtHD,
          storage.subscriptionTierPortraitArt,
          storage.subscriptionTierPortraitArtHD,
        ],
      },
      subscriptionTierEdit: {
        id: 'subscriptionTierEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [
          storage.subscriptionTierSquareArt,
          storage.subscriptionTierSquareArtHD,
          storage.subscriptionTierLandscapeArt,
          storage.subscriptionTierLandscapeArtHD,
          storage.subscriptionTierPortraitArt,
          storage.subscriptionTierPortraitArtHD,
        ],
      },
      subscriptionTierDestroy: {
        id: 'subscriptionTierDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [
          storage.subscriptionTierSquareArt,
          storage.subscriptionTierSquareArtHD,
          storage.subscriptionTierLandscapeArt,
          storage.subscriptionTierLandscapeArtHD,
          storage.subscriptionTierPortraitArt,
          storage.subscriptionTierPortraitArtHD,
        ],
      },
      subscriptionTierRead: {
        id: 'subscriptionTierRead',
        allowedRoles: [roles.admin, roles.readonly],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      subscriptionTierAutocomplete: {
        id: 'subscriptionTierAutocomplete',
        allowedRoles: [roles.admin, roles.readonly],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      entitlementImport: {
        id: 'entitlementImport',
        allowedRoles: [],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      entitlementCreate: {
        id: 'entitlementCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      entitlementEdit: {
        id: 'entitlementEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      entitlementDestroy: {
        id: 'entitlementDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      entitlementRead: {
        id: 'entitlementRead',
        allowedRoles: [roles.admin, roles.readonly],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      entitlementAutocomplete: {
        id: 'entitlementAutocomplete',
        allowedRoles: [roles.admin, roles.readonly],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      userEntitlementImport: {
        id: 'userEntitlementImport',
        allowedRoles: [],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userEntitlementCreate: {
        id: 'userEntitlementCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      userEntitlementEdit: {
        id: 'userEntitlementEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      userEntitlementDestroy: {
        id: 'userEntitlementDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      userEntitlementRead: {
        id: 'userEntitlementRead',
        allowedRoles: [roles.admin, roles.readonly],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userEntitlementAutocomplete: {
        id: 'userEntitlementAutocomplete',
        allowedRoles: [roles.admin, roles.readonly],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      usageLogImport: {
        id: 'usageLogImport',
        allowedRoles: [],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      usageLogCreate: {
        id: 'usageLogCreate',
        allowedRoles: [],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      usageLogEdit: {
        id: 'usageLogEdit',
        allowedRoles: [],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      usageLogDestroy: {
        id: 'usageLogDestroy',
        allowedRoles: [],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      usageLogRead: {
        id: 'usageLogRead',
        allowedRoles: [roles.admin, roles.readonly],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      usageLogAutocomplete: {
        id: 'usageLogAutocomplete',
        allowedRoles: [roles.admin, roles.readonly],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      userAffirmationProgramImport: {
        id: 'userAffirmationProgramImport',
        allowedRoles: [],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userAffirmationProgramCreate: {
        id: 'userAffirmationProgramCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      userAffirmationProgramEdit: {
        id: 'userAffirmationProgramEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      userAffirmationProgramDestroy: {
        id: 'userAffirmationProgramDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      userAffirmationProgramRead: {
        id: 'userAffirmationProgramRead',
        allowedRoles: [roles.admin, roles.readonly],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userAffirmationProgramAutocomplete: {
        id: 'userAffirmationProgramAutocomplete',
        allowedRoles: [roles.admin, roles.readonly],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
    };
  }

  static get asArray() {
    return Object.keys(this.values).map((value) => {
      return this.values[value];
    });
  }
}

export default Permissions;
