import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import affirmationActions from 'src/modules/affirmation/affirmationActions';
import affirmationSelectors from 'src/modules/affirmation/affirmationSelectors';
import authActions from 'src/modules/auth/authActions';
import authSelectors from 'src/modules/auth/authSelectors';
import layoutActions from 'src/modules/layout/layoutActions';

function Header(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  let showHeader  = true;
const pathArray = location.pathname.split("/")

if(pathArray.includes("home")){
  showHeader = false;
}

  const doToggleMenu = () => {
    dispatch(layoutActions.doToggleMenu());
  };

  const userText = useSelector(
    authSelectors.selectCurrentUserNameOrEmailPrefix,
  );

  const doSignout = () => {
    dispatch(authActions.doSignout());
  };

  const affirmationToday = useSelector(
    affirmationSelectors.selectAffirmationToday,
  );

  useEffect(() => {
    dispatch(affirmationActions.fetchToday());
  }, []);

  return (
    <>
  
   <nav className={`fixed z-10 flex h-20 w-full flex-shrink-0 ${showHeader === false && "show-nav"}`}>
      <button
        onClick={doToggleMenu}
        className="focus:outline-none px-4 text-gray-500 block lg:hidden"
      >
        <svg
          className="h-6 w-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h16M4 18h7"
          />
        </svg>
      </button>

    { showHeader &&  <div className="flex flex-1 items-center px-4 bg-white h-20">
        {process.env.REACT_APP_ENVIRONMENT ===
          'staging' && (
          <span className="mr-3 inline-flex items-center rounded-md bg-red-100 px-2.5 py-0.5 text-sm font-medium text-red-800">
            Staging Environment
          </span>
        )}
        <div className="flex flex-1 flex-col justify-start gap-1">
        <span className='text-sm font-medium font-syneMedium' style={{color:'#ccd8db'}}>Today’s intentions</span>
          {affirmationToday && (
            <span className="text-base font-SFProDisplayMedium font-medium text-darkGreen">
              {location.pathname == '/mindful-intentions'
                ? `Today’s Mindful Intention`
                : affirmationToday.text}
            </span>
          )}
        </div>
      </div>}
      
    </nav>
   { <div className={`${showHeader===false&& "show-nav"}`} style={{ height:"80px" }}>&#160;</div>}

    </>
  );
}

export default Header;
