import React from 'react';
import ReactDOM from 'react-dom';
import config from 'src/config';
import { i18n, init as i18nInit } from 'src/i18n';
import { AuthPlan } from 'src/modules/auth/authPlan';
import AuthService from 'src/modules/auth/authService';
import './index.css';
import { AuthToken } from './modules/auth/authToken';
import TagManager from 'react-gtm-module';

//
(async function () {
  if (config.pixelId) {
    try {
      // @ts-ignore
      window.fbq('init', '357164989128215');
      // @ts-ignore
      window.fbq('track', 'PageView');
    } catch (error) {
      console.error(error);
    }
  }

  if (config.googleTagManagerId) {
    try {
      TagManager.initialize({
        gtmId: config.googleTagManagerId,
      });
    } catch (error) {
      console.error(error);
    }
  }

  const isSocialOnboardRequested =
    AuthService.isSocialOnboardRequested();
  AuthPlan.applyFromLocationUrlIfExists();
  AuthToken.applyFromLocationUrlIfExists();
  if (isSocialOnboardRequested) {
    await AuthService.socialOnboard();
  }
  await i18nInit();

  const App = require('./App').default;
  document.title = i18n('app.title');
  ReactDOM.render(<App />, document.getElementById('root'));
})();
