import actions from 'src/modules/affirmation/affirmationActions';

const initialData = {
  affirmationToday: null,
};

export default (state = initialData, { type, payload }) => {
  if (type === actions.AFFIRMATION_TODAY_SUCCESS) {
    return {
      ...state,
      affirmationToday: payload,
    };
  }

  if (type === actions.AFFIRMATION_TODAY_ERROR) {
    return {
      ...state,
      affirmationToday: null,
    };
  }

  return state;
};
