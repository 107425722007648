import PermissionChecker from 'src/modules/auth/permissionChecker';
import React, { useEffect } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';

function PublicRoute({
  component: Component,
  currentTenant,
  currentUser,
  ...rest
}) {
  const location = useLocation();
  const pathArray = location.pathname.split("/")
 
  const chatBot = document.getElementById("gpt-trainer-window-button");
  const chatBotIframe = document.getElementById("gpt-trainer-window-iframe");
  const chatBotCloseIcon = document.getElementById("gpt-trainer-close-chat-icon");

  useEffect(()=>{
     if(localStorage.getItem("jwt") && chatBot){
       chatBot.style.display = "flex"
     }else if((!localStorage.getItem("jwt")|| localStorage.getItem("jwt")==="") && chatBot){
         chatBot.style.display = "none"
         if(chatBotIframe){
          chatBotIframe.style.display = "none"
          chatBotCloseIcon.style.display = "none"
         }
     }
   },[chatBot])
  return (
    <Route
      {...rest}
      render={(props) => {
        const permissionChecker = new PermissionChecker(
          currentTenant,
          currentUser,
        );

        if (permissionChecker.isAuthenticated && pathArray.includes("signin")) {
          return <Redirect to="/" />;
        }

        return <Component {...props} />;
      }}
    />
  );
}

export default PublicRoute;
