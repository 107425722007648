import { createSelector } from 'reselect';

const selectRaw = (state) => state.affirmation;

const selectAffirmationToday = createSelector(
  [selectRaw],
  (raw) => raw.affirmationToday,
);

const affirmationSelectors = {
  selectRaw,
  selectAffirmationToday,
};

export default affirmationSelectors;
