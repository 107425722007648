import authAxios from 'src/modules/shared/axios/authAxios';

export default class UsageLogService {
  static async create({
    localTimestamp,
    type,
    affirmationProgram,
    affirmation,
    soundTitle,
    sound,
    device,
    payload,
  }) {
    const body = {
      data: {
        localTimestamp,
        type,
        affirmationProgram,
        affirmation,
        soundTitle,
        sound,
        device,
        payload,
      },
    };

    const response = await authAxios.post(
      `/usage-log`,
      body,
    );

    return response.data;
  }
}
